import logo from "./logo.svg";
import "./App.css";
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Footer from "./components/footer/Footer";
import companyLogo from "./assets/header-assets/logo4.svg";
// import Event1Details from "./components/events/event_pages/event1";
// import Event2Details from "./components/events/event_pages/event2";
// import Event7Details from "./components/events/event_pages/event7";
// import Event8Details from "./components/events/event_pages/event8";
// import Event9Details from "./components/events/event_pages/event9";
// import Event10Details from "./components/events/event_pages/event10";
// import Event11Details from "./components/events/event_pages/event11";
// import Event12Details from "./components/events/event_pages/event12";
// import Event14Details from "./components/events/event_pages/event14";
// import Event15Details from "./components/events/event_pages/event15";
// import Event16Details from "./components/events/event_pages/event16";
// import Event18Details from "./components/events/event_pages/event18";
// import Event20Details from "./components/events/event_pages/event20";
// import Event25Details from "./components/events/event_pages/event25";

// import Event24Details from "./components/events/event_pages/event24";
import Event21Details from "./components/events/event_pages/event21";
import Event22Details from "./components/events/event_pages/event22";
import Event23Details from "./components/events/event_pages/event23";
// import Event26Details from "./components/events/event_pages/event26";
// import Event27Details from "./components/events/event_pages/event27";
// import Event28Details from "./components/events/event_pages/event28";
import Event29Details from "./components/events/event_pages/event29";
// import Event13Details from "./components/events/event_pages/event13";
// import Event17Details from "./components/events/event_pages/event17";
import Event19Details from "./components/events/event_pages/event19";
// import Event30Details from "./components/events/event_pages/event30";
import Event31Details from "./components/events/event_pages/event31";
import Event32Details from "./components/events/event_pages/event32";
import Event33Details from "./components/events/event_pages/event33";
import Event34Details from "./components/events/event_pages/event34";
import Event35Details from "./components/events/event_pages/event35";
import Event36Details from "./components/events/event_pages/event36";
import Event37Details from "./components/events/event_pages/event37";
import Event38Details from "./components/events/event_pages/event38";
import Event39Details from "./components/events/event_pages/event39";
import Event40Details from "./components/events/event_pages/event40";
import Event41Details from "./components/events/event_pages/event41";

import NewsLetter from "./pages/newsletter/newsletter";
import CoverPageHeader from "./components/cover_page_header/cover_page_header";
import CoverPage from "./pages/coverpage/coverpage";
import ListNews from "./pages/list_news/listnews";
import NewsInsider from "./pages/news_insider/news_insider";
import ListCatNews from "./pages/list_cat/list_cat";
import Editorial from "./pages/editorial/editorial";
import ImageFlipBook from "./pages/magazine_demo/magazine";
import About from "./pages/about/About";
import Events from "./pages/events/events";
import ContactUs from "./pages/contact/contact";
import TermsAndConditions from "./pages/terms/terms";
import PrivacyPolicy from "./pages/privacy/privacy";
import ChemicalTodayTV from "./pages/ct-tv/chemicaltodaytv";
import SubscribeForm from "./pages/subscribe/subscribe";
import Sustainability from "./pages/events/events/sustainability";
import ThankYouPage from "./components/thankyou/thankyou";
import AdvertiseWithUs from "./pages/advtwithus/advtwithus";
import ThankYouPage2 from "./components/thankyou/thankyou2";
import { isMobile, isTablet, isWinPhone } from "react-device-detect";
import SearchPage from "./pages/search/search";
import BasicExample from "./components/resp_header/resp_header";
import Event3Details from "./components/events/event_pages/event3";
import Event4Details from "./components/events/event_pages/event4";
import { Helmet } from "react-helmet";
import PdfViewer from "./pages/PdfViewer/PdfViewer";
import CompactEdition from "./pages/compact-edition/compact-edition";
import mediakit from "./assets/Chemical_Today_Media_kit.pdf";

import editorialcalender from "./assets/Chemical_Today_Editorial_Calendar.pdf";
import Event5Details from "./components/events/event_pages/event5";
import Event6Details from "./components/events/event_pages/event6";
import S3PdfViewer from "./pages/s3pdfviewer/s3pdfviewer";
import Pdf from "./pages/LetterView/pdf";

function App({ currentPath }) {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const excludeHeaderFooterRoutes = [
    "/events/sustainability",
    "/events/sustainability",
  ];
  const shouldExcludeHeaderFooter =
    excludeHeaderFooterRoutes.includes(currentPath);
  console.log(currentPath);

  return (
    <Router>
      <div className="noselect">
        {!shouldExcludeHeaderFooter && currentPath.startsWith("/magazine") ? (
          screenWidth <= 1100 ? (
            <BasicExample />
          ) : (
            <BasicExample />
          )
        ) : screenWidth <= 1100 || isTablet || isMobile ? (
          <BasicExample />
        ) : (
          <CoverPageHeader />
        )}

        <Routes>
          <Route path="/editorial" element={<Editorial />} />
          <Route path="/newsletters" element={<NewsLetter />} />
          {/* ----newsletters change------ */}
          <Route path="/newsletters/:folder/:file" element={<Pdf />} />
          {/* event routes */}
          {/* <Route
            path="/industrial_events/PMFAI_ICSCE_-_Trade_Show_Exhibition_and_Conference"
            element={<Event28Details />}
          /> */}
          <Route
            path="/industrial_events/Drug_Safety_Symposium_2025_-_Dubai_Chapter"
            element={<Event29Details />}
          />
          <Route
            path="/industrial_events/IPF_Bangladesh_2025"
            element={<Event34Details />}
          />
          <Route
            path="/industrial_events/CII’s_Water_&_Waste_Expo_2025"
            element={<Event19Details />}
          />
          <Route
            path="/industrial_events/Oman_Petroleum_and_Energy_Show"
            element={<Event31Details />}
          />
          <Route
            path="/industrial_events/2nd_Asia_Sustainable_Plastics_Summit_2025"
            element={<Event36Details />}
          />
          <Route
            path="/industrial_events/23rd_ARC_Industry_Leadership_Forum_Bangalore"
            element={<Event32Details />}
          />
          <Route
            path="/industrial_events/INACOATING_2025"
            element={<Event21Details />}
          />
          <Route
            path="/industrial_events/INAGRICHEM_2025"
            element={<Event22Details />}
          />
          <Route
            path="/industrial_events/CHEMICAL_INDONESIA_2025"
            element={<Event23Details />}
          />
          <Route
            path="/industrial_events/20th_EverythingAboutWater_Expo_2025"
            element={<Event33Details />}
          />
          <Route
            path="/industrial_events/The_2nd_Asia_Supply_Chain_Decarbonization_and_Sustainability_Summit_2025"
            element={<Event35Details />}
          />
          <Route
            path="/industrial_events/The_3rd_Europe_Green_Packaging_Innovation_Summit_2025"
            element={<Event37Details />}
          />
          <Route
            path="/industrial_events/Europe_Chemical_Recycling_Innovation_Summit_2025"
            element={<Event38Details />}
          />
          <Route
            path="/industrial_events/Global_Regulatory_Updates,_Deadline_and_Opportunities_for_25-26"
            element={<Event39Details />}
          />
          <Route
            path="/industrial_events/CPHI_&_PMEC_China_2025"
            element={<Event40Details />}
          />
          <Route
            path="/industrial_events/Cosmetics_Ingredients_Expo"
            element={<Event41Details />}
          />
          {/* ------------end------------ */}
          {/* <Route
            path="/industrial_events/CPHI_&_PMEC_India_2024"
            element={<Event13Details />}
          /> */}

          {/* 
          <Route
            path="/industrial_events/Plastasia_2025"
            element={<Event27Details />}
          />
          <Route
            path="/industrial_events/Rethink_Circularity:_European_Circular_Economy_Summit_2024"
            element={<Event24Details />}
          /> */}
          {/* <Route
            path="/industrial_events/Industrial_Decarbonization_Summit_Road_to_Net_Zero(IDS)"
            element={<Event17Details />}
          /> */}
          {/* <Route
            path="/industrial_events/PharmaTech_&_LabTech_Expo_2025"
            element={<Event30Details />}
          /> */}

          {/* <Route
            path="/industrial_events/ICC_Sustainability_Conclave_2024"
            element={<Event26Details />}
          /> */}

          <Route path="/magazine/:folderName" element={<ImageFlipBook />} />
          <Route path="/about" element={<About />} />
          <Route path="/subscribe/thank-you" element={<ThankYouPage />} />
          <Route path="/contact/thank-you" element={<ThankYouPage2 />} />

          <Route path="/advertise-with-us" element={<AdvertiseWithUs />} />

          <Route path="/" element={<CoverPage />} />
          <Route path="/:newsType/:pageNo" element={<ListNews />} />

          <Route path="/news/:newsType/:id/:title?" element={<NewsInsider />} />
          {/* <Route path="/news/:newsType/:id/:title?" component={<NewsInsider/>} /> */}

          <Route path="/:newsType/:cat/:pageNo" element={<ListCatNews />} />

          <Route path="/events" element={<Events />} />
          <Route path="/events/sustainability" element={<Sustainability />} />

          <Route path="/contact" element={<ContactUs />} />
          <Route path="/privacy-and-poilicies" element={<PrivacyPolicy />} />

          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/chemical-today-tv" element={<ChemicalTodayTV />} />
          <Route path="/subscribe" element={<SubscribeForm />} />
          <Route path="/search" element={<SearchPage />} />
          <Route
            path="/magazine/:year/:folder/:file"
            element={<S3PdfViewer />}
          />
          <Route
            path="/pdf-viewer/media-kit"
            element={<PdfViewer file={mediakit} />}
          />
          <Route
            path="/compact-edition"
            element={
              <CompactEdition file="https://chemicaltodaywebsiet.s3.amazonaws.com/Compact+Edition.pdf" />
            }
          />

          <Route
            path="/pdf-viewer/editorial-calendar"
            element={<PdfViewer file={editorialcalender} />}
          />
        </Routes>
        {!shouldExcludeHeaderFooter && <Footer />}
      </div>
    </Router>
  );
}

export default App;
